<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidationUnion_scanNumber'])"></div>
        <SearchInput
            class="scan-ico fast-order-search"
            :label="$t('consolidationUnion_scanNumber.localization_value.value')"
            :disabled="$store.getters.getExpressFastOrdersBtn"
            v-model="scanValue"
            @submit="scan($event)"
            @keyup.native="scan($event, true)"
        />
        <div class="color-red pt-2"
             v-if="scanErrorItem !== ''"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidationUnion_notFound'])"></div>
          {{"'" + scanErrorItem + "'"}} {{$t('consolidationUnion_notFound.localization_value.value')}}
        </div>
      </div>
    </div>

    <div v-for="(item, index) in EFO.data.relatedOrders"
           :key="index">
      <div class="order-create__title-horizontal"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['profile_uploadDocuments'])"></span>
        <TitleHorizontal
            :value="'Express #' + item.data.id + ' - ' + getUserContactDataFromUser(item.data.orderObj.order_express.user).user_full_name"
            :rightBtn="true"
            :rightBtnType="'delete'"
            :rightBtnText="$t('common_delete.localization_value.value')"
            @rightBtnClick="EFO.removeRelatedOrders(index)"
        />
      </div>

      <div class="block-table__row">
        <div class="block-table__col block-table__col--16">
          <div class="block-table__label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            {{$t('packaging_Weight.localization_value.value')}}
          </div>
          <div class="block-table__content">
            {{item.data.OldDimensions.data.weight}}
          </div>
        </div>
        <div class="block-table__col block-table__col--16">
          <div class="block-table__label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            {{$t('packaging_Width.localization_value.value')}}
          </div>
          <div class="block-table__content">
            {{item.data.OldDimensions.data.width}}
          </div>
        </div>
        <div class="block-table__col block-table__col--16">
          <div class="block-table__label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            {{$t('packaging_Height.localization_value.value')}}
          </div>
          <div class="block-table__content">
            {{item.data.OldDimensions.data.height}}
          </div>
        </div>
        <div class="block-table__col block-table__col--16">
          <div class="block-table__label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            {{$t('packaging_Length.localization_value.value')}}
          </div>
          <div class="block-table__content">
            {{item.data.OldDimensions.data.dimensionLength}}
          </div>
        </div>
        <div class="block-table__col block-table__col--16">
          <div class="block-table__label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            {{$t('fbm_TrackingNumber.localization_value.value')}}
          </div>
          <div class="block-table__content">
            <div class="mr-1" v-if="item.data.orderObj && item.data.orderObj.order_express && item.data.orderObj.order_express.packaging_type">
              <span v-if="item.data.orderObj.order_express.packaging_type === FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.value" class="brick-btn label-circle--violet">PAK</span>
              <span v-if="item.data.orderObj.order_express.packaging_type === FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.value" class="brick-btn label-circle--orange">ENV</span>
              <span v-if="item.data.orderObj.order_express.packaging_type === FEDEX_PACKAGING_TYPES.HIMSELF_FEDEX_PACKAGING_TYPE.value" class="brick-btn label-circle--green">YOUR</span>
            </div>
            <ValueHelper
                :value="item.data.orderObj"
                :deep="'old_tracking_number'"
            />
          </div>
        </div>
        <div class="block-table__col block-table__col--16" v-if="item.data.orderObj.order_express.warehouse">
          <div class="block-table__label d-flex"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            {{$t('ex_warehouse.localization_value.value')}}

            <v-popover
                v-if="item.data.orderObj.order_express && item.data.orderObj.order_express.orderNotification.length"
                class="site-tooltip ml-auto"
                offset="5"
                placement="top"
                trigger="hover"
            >
              <TooltipBtn
                  class="tooltip-target notification-tooltip"
              />
              <template slot="popover">
                <p>
                  <b style="font-size: 16px;">{{$t('common_OrderNotification.localization_value.value')}}</b>
                </p>
                <p>
                  <ValueHelper
                      style="font-size: 14px;"
                      :value="item.data.orderObj"
                      :deep="'order_express.orderNotification.' + (item.data.orderObj.order_express.orderNotification.length - 1) + '.description'"
                  />
                </p>
              </template>
            </v-popover>
          </div>
          <div class="block-table__content" v-if="item.data.orderObj.order_express && item.data.orderObj.order_express.warehouse">
            <ValueHelper
                class="bold"
                :class="{
                  'red-color': item.data.orderObj.order_express.warehouse.id === KIEV_WAREHOUSE.id,
                  'orange-color': item.data.orderObj.order_express.warehouse.id === LODZ_WAREHOUSE.id,
                  'green-color': item.data.orderObj.order_express.warehouse.id === PYRZOWICE_WAREHOUSE.id
                }"
                :value="item.data.orderObj.order_express.warehouse"
                :deep="'currentTranslate.name'"
            />
          </div>
        </div>
      </div>


      <div class="order-create__row custom-row">


        <div class="custom-col custom-col--25 custom-col--sm-50 position-relative mb-3">
          <div style="position: absolute; top: -7px; right: 10px; z-index: 2;" v-if="item.data.changedDimensions">
            <img style="width: 15px;" src="/img/common/check.svg" alt="ico">
          </div>
          <DefaultInput
              :label="$t('packaging_Weight.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.data.Dimensions.validation.weight}"
              :error="item.data.Dimensions.validation.weight"
              :errorTxt="$t(`${item.data.Dimensions.validationTranslate.weight}.localization_value.value`)"
              v-model="item.data.Dimensions.data.weight"
              @input.native="changeWeight($event, item)"
          />
        </div>
        <div class="custom-col custom-col--25 custom-col--sm-50 position-relative mb-3">
          <div style="position: absolute; top: -7px; right: 10px; z-index: 2;" v-if="item.data.changedDimensions">
            <img style="width: 15px;" src="/img/common/check.svg" alt="ico">
          </div>
          <DefaultInput
              :label="$t('packaging_Width.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.data.Dimensions.validation.width}"
              :error="item.data.Dimensions.validation.width"
              :errorTxt="$t(`${item.data.Dimensions.validationTranslate.width}.localization_value.value`)"
              v-model="item.data.Dimensions.data.width"
          />
        </div>
        <div class="custom-col custom-col--25 custom-col--sm-50 position-relative mb-3">
          <div style="position: absolute; top: -7px; right: 10px; z-index: 2;" v-if="item.data.changedDimensions">
            <img style="width: 15px;" src="/img/common/check.svg" alt="ico">
          </div>
          <DefaultInput
              :label="$t('packaging_Height.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.data.Dimensions.validation.height}"
              :error="item.data.Dimensions.validation.height"
              :errorTxt="$t(`${item.data.Dimensions.validationTranslate.height}.localization_value.value`)"
              v-model="item.data.Dimensions.data.height"
          />
        </div>
        <div class="custom-col custom-col--25 custom-col--sm-50 position-relative mb-3">
          <div style="position: absolute; top: -7px; right: 10px; z-index: 2;" v-if="item.data.changedDimensions">
            <img style="width: 15px;" src="/img/common/check.svg" alt="ico">
          </div>
          <DefaultInput
              :label="$t('packaging_Length.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.data.Dimensions.validation.dimensionLength}"
              :error="item.data.Dimensions.validation.dimensionLength"
              :errorTxt="$t(`${item.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
              v-model="item.data.Dimensions.data.dimensionLength"
          />
        </div>
      </div>

      <div class="d-flex align-items-center mb-3" v-if="item.data.orderObj && item.data.orderObj.new_tracking_number">
        <div class="block-table__label mb-0 mr-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          {{$t('ex_NewTrackingNumber.localization_value.value')}}
        </div>
        <div class="block-table__content">
          <ValueHelper
              :value="item.data.orderObj"
              :deep="'new_tracking_number'"
          />
        </div>
      </div>

      <div class="d-flex flex-wrap mb-5">
          <MainButton
              v-if="!useDimensionsScale"
              class="wfc mr-3 mb-2"
              :value="'Add Scales'"
              @click.native="checkDimensionsScale(false)"
          />
          <MainButton
              v-if="!item.data.changedDimensions"
              class="wfc mr-3 mb-2"
              :value="$t('ex_updateDimensions.localization_value.value')"
              @click.native="updateDimensions(item)"
          />
          <MainButton
              class="wfc"
              :value="$t('ex_reCreateLabel.localization_value.value')"
              @click.native="createLabel(item)"
          />
      </div>

    </div>

    <video style="width: 0 !important; height: 0 !important; padding: 0 !important; margin: 0 !important;"></video>

  </div>
</template>

<script>

import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
import {FBMMixinsHelper} from "../../../../../mixins/FBMMixins/FBMMixins";
import SearchInput from "../../../../UI/inputs/SearchInput/SearchInput";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import {scaleMixins} from "@/mixins/scaleMixins/scaleMixin";
import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
import {FEDEX_PACKAGING_TYPES, KIEV_WAREHOUSE, LODZ_WAREHOUSE, PYRZOWICE_WAREHOUSE} from "@/staticData/staticVariables";

export default {
  name: "ScannerBlock",
  components: {
    TooltipBtn,
    MainButton,
    ValueHelper,
    TitleHorizontal,
    DefaultInput,
    // DefaultSelect,
    // ValueHelper,
    // LinkButton,
    // ManagerButton,
    // DefaultCheckbox,
    SearchInput,
  },

  mixins: [mixinDetictingMobile, FBMMixinsHelper, scaleMixins],

  props: {
    EFO: Object,
    created: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      scanValue: '',
      scanErrorItem: '',

      foundDevice: false,
      weightScaleLB: 0,
      weightScaleOZ: 0,

      newDimensions: false,

      useDimensionsScale: false,

      FEDEX_PACKAGING_TYPES: FEDEX_PACKAGING_TYPES,

      KIEV_WAREHOUSE: KIEV_WAREHOUSE,
      LODZ_WAREHOUSE: LODZ_WAREHOUSE,
      PYRZOWICE_WAREHOUSE: PYRZOWICE_WAREHOUSE,
    }
  },

  async mounted() {
    /**
     * Init scale device
     */
    await this.checkDimensionsScale(true)
    // if(this._.has(this.currentPermissions, this.PERMISSIONS.USER_CAN_USE_SCALES) && navigator.hid) {
    //   await navigator.hid.getDevices().then(async devices => {
    //     let device = this._.find(devices, {vendorId: 2338})
    //     if(!device) return
    //     this.foundDevice = true
    //     await device.open().then(() => {
    //       this.changeWeightScaleMixin(device)
    //     })
    //   })
    // }

    // this.$store.dispatch('getRatesList').then(response => {
    //   this.ratesListOptions = this.getRespData(response)
    //   if(this.created && this.ratesListOptions.length > 0) {
    //     this.EFO.data.ratesListSelected = this.ratesListOptions[0]
    //   }
    // })
  },

  methods: {

    async getDimensionsScaleData(port) {
      let returnData = false
      await port.open({ baudRate: 9600 })
      await new Promise(resolve => setTimeout(resolve, 800))
      const textDecoder = new window.TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();

      // Listen to data coming from the serial device.
      let flag = true
      while (flag) {
        const { value, done } = await reader.read();
        if (done) {
          // Allow the serial port to be closed later.
          reader.releaseLock();
          break;
        }
        // value is a string.
        console.log(value);
        returnData = value

        if(value.length > 15){
          reader.cancel();
          reader.releaseLock();
          await readableStreamClosed.catch(() => { /* Ignore the error */ });
          await port.close();
          break;
        }
      }

      return returnData
    },

    parseDimensionsScaleData(dimensionsScaleData) {
      let dimensions = dimensionsScaleData.split('-')
      if(!dimensions.length) return false

      let dimensionLength = dimensions[1].split('cm')[0]
      let width = dimensions[2].split('cm')[0]
      let height = dimensions[3].split('cm')[0]

      let weight = dimensionsScaleData.split(':')[1].split('kg')[0].replaceAll(' ', '')

      let scaleDimensions = {
        dimensionLength: 83.3,
        width: 83.5,
        height: 96.2,
      }

      dimensionLength = Math.ceil(scaleDimensions.dimensionLength - dimensionLength)
      width = Math.ceil(scaleDimensions.width - width)
      height = Math.ceil(scaleDimensions.height - height)

      console.log(dimensionLength, width, height, weight);

      return {dimensionLength, width, height, weight}
    },

    async checkDimensionsScale(mounted = false) {
      const filters = [
        { usbVendorId: 0x067B},
      ];

      let ports = await navigator.serial.getPorts({filters})

      if(ports.length) {
        this.useDimensionsScale = true
        return
      }

      if(mounted) return

      let port = await navigator.serial.requestPort({filters})
      if(port) {
        this.useDimensionsScale = true
        return
      }

      this.openNotify('error', 'common_notificationUndefinedError')
    },

    async useDimensionScales(item) {
      const filters = [
        { usbVendorId: 0x067B},
      ];

      let ports = await navigator.serial.getPorts({filters})
      if(ports.length) {
        let port = ports[0]
        let dimensionsScaleData = await this.getDimensionsScaleData(port)
        if(!dimensionsScaleData) return this.openNotify('error', 'common_notificationUndefinedError')

        let data = this.parseDimensionsScaleData(dimensionsScaleData)

        if(!data) return this.openNotify('error', 'common_notificationUndefinedError')

        item.data.Dimensions.data.weight = data.weight
        item.data.Dimensions.data.width = data.width
        item.data.Dimensions.data.height = data.height
        item.data.Dimensions.data.dimensionLength = data.dimensionLength
      }

      // navigator.serial.requestPort({filters}).then(async port => {
      //
      //   let dimensionsScaleData = await this.getDimensionsScaleData(port)
      //   if(!dimensionsScaleData) return this.openNotify('error', 'common_notificationUndefinedError')
      //
      //   let data = this.parseDimensionsScaleData(dimensionsScaleData)
      //
      //   if(!data) return this.openNotify('error', 'common_notificationUndefinedError')
      //
      //   item.data.Dimensions.data.weight = data.weight
      //   item.data.Dimensions.data.width = data.width
      //   item.data.Dimensions.data.height = data.height
      //   item.data.Dimensions.data.dimensionLength = data.dimensionLength
      //
      //   this.useDimensionsScale = true
      // })

    },

    createLabel(item) {
      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_yes',
        no: 'common_no'
      }

      let confirm = () => {

        item.data.reCreateLabel = true
        item.data.isNewDimensions = true
        this.$emit('updateOrder', item.data.orderObj?.order_express_id)

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    updateDimensions(item) {
      let valid = true
      this.EFO.data.relatedOrders.forEach(order => {
        if((order.data.isNewDimensions || order.data.changedDimensions) && !order.data.Dimensions.dimensionsValidate()) {
          valid = false
        }
      })

      if(!valid || !item.data.Dimensions.dimensionsValidate()) return

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_yes',
        no: 'common_no'
      }

      let confirm = () => {

        item.data.isNewDimensions = true
        item.data.takePhoto = true
        this.$emit('updateOrder', false, true)

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)

    },

    changeWeight(val, item) {
      if(val.target.value === ' ' && this.useDimensionsScale) return this.useDimensionScales(item)
      item.data.Dimensions.data.weight = val.target.value
    },

    removeRelatedOrder(index) {
      this.EFO.removeRelatedOrders(index)
    },

    scan(e, checkPressedKey = false) {

      if ((checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') || !this.scanValue.length) return

      this.scanErrorItem = ''

      this.$store.dispatch('getFastExpressOrderById', this.scanValue).then((response) => {

        let respData = this.getRespData(response)
        console.log(respData);

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */

          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.EFO.addRelatedOrders(respData?.order_express_fast_order_element)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            this.scanErrorItem = this.scanValue
            break
          }
            /**
             * Undefined Error
             */
          default: {
            break
          }
        }

        //reset search input
        this.scanValue = ''
        document.querySelector('.fast-order-search .search-input__input').focus()

      })
    },

  }
}
</script>

<style scoped>

  .notification-tooltip >>> svg {
    transform: scale(2.5);
  }

  .notification-tooltip >>> circle {
    fill: #f53333;
  }

  .red-color {
    color: red;
  }

  .orange-color {
    color: orange;
  }

  .green-color {
    color: green;
  }

</style>